import { Route, Routes } from "react-router-dom";
import { Home } from "./Components/Home/Home.js";


export function Routing() {
  return (
    <Routes>
      <Route path="/" element={<Home></Home>} />
      <Route path="/Home" element={<Home></Home>} />
    </Routes>
  );
}
