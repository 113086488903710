import { Nav, Navbar, Image, Offcanvas
} from "../../bootstrap.js";
// import { ContactButtonNav } from "../ContactButton/ContactButtonNav.js";
import "./navComp.css";
import { OrgTitle } from "./OrgTitle.js";
import classNames from "classnames";
import { useMediaQuery } from "usehooks-ts";
import { useState } from "react";
const landingPage = "Home";

const navTitles = [
  { id: "1", title: `${landingPage}`, url: "Home" },
];

//custom- if absolutely necessary
export function NavComp() {
  const isMobile = useMediaQuery("(max-width: 1030px)");
  const isMobile2 = useMediaQuery("(max-width: 992px)");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="navbarPositioning">
        <Navbar.Brand>
          <a href="/" className="brandLink">
            <div className="brandPositioning">
              {isMobile ? (
                <></>
              ) : (
                <>
                  <OrgTitle></OrgTitle>
                  <Image
                    src="/images/logo2.png"
                    className="img-fluid logo"
                  ></Image>
                </>
              )}
            </div>
          </a>
        </Navbar.Brand>

        {isMobile ? (
          <>
            <a href="/" className="brandLink">
              <div className="brandPositioning">
                <OrgTitle></OrgTitle>
                <Image
                  src="/images/logo2.png"
                  className="img-fluid logo"
                ></Image>
              </div>
            </a>
          </>
        ) : (
          <></>
        )}
        <Navbar expand="lg" className={classNames("bg-body-primary", "nav1")}>
        <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={handleShow}
              />

<Offcanvas show={show} onHide={handleClose} responsive="lg">
                <Offcanvas.Body>
             
                  <Nav>
              {navTitles.map((navTitle) => (
                <>
                  {navTitle.title === landingPage ? (
                    <Nav.Link key={navTitle.id} href="/">
                      {navTitle.title}
                    </Nav.Link>
                  ) : (
                    <Nav.Link key={navTitle.id} href={`/${navTitle.url}`}>
                      {navTitle.title}
                    </Nav.Link>
                  )}
                </>
              ))}
            </Nav>
                </Offcanvas.Body>
              </Offcanvas>

   
        </Navbar>
        {/* {isMobile ? <></> : <ContactButtonNav></ContactButtonNav>} */}
      </div>
      {/* {isMobile ? <ContactButtonNav></ContactButtonNav> : <></>} */}
    </div>
  );
}
