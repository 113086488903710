
import './App.css';

import "bootstrap/dist/css/bootstrap.min.css";

import { NavComp } from "./Components/Nav/NavComp.js";
import { Footer } from "./Components/Footer/Footer.js";
import { Routing } from "./Routing.js";

function App() {
  return (
    <div >
        <NavComp/>
       <div className="pageLayout">
        <Routing></Routing>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
