import { Header } from "../Header/Header.js";
import { Sonification } from "../Sonification/Sonification.js";
import {Row, Col} from "../../bootstrap.js";
import "./home.css";

export function Home() {
  return (
    <div>
      <Header header="Social Good"></Header>
      {/* <div>
        <i class=" bi-handshake"></i>
        <h4>Volunteering</h4>
      </div>
      <div>
        <i class=" bi-people-fill"></i>
        <h4>Hackathons</h4>
      </div>
      <div>
        <i class=" bi-people-fill"></i>
        <h4>Equity and Inclusion</h4>
      </div> */}
      <div>
      <h4 className="assistiveTech"> Assistive Tech</h4>
      {/* <h5>Assistive Tech</h5>  */}
     <Row className="socialProducts gy-5">
        <Col md={6} className="soP">
          <h5>Sonification</h5>
          <p>Sonification gives an audio representation of visual data. </p>
          <p>* Hover mouse over blue graph line and trace for sample audio representation.</p>
          <Sonification></Sonification>
        </Col>
        <Col md={6}>
          <h5>Dark Mode</h5>
          <Col md={12} className="comingSoon">
             <p>Coming Soon!</p>
          </Col>
         
        </Col>
        <Col md={6}>
          <h5>AI generated audio descriptions and closed captioning for videos. </h5>
          <Col md={12} className="comingSoon">
             <p>Coming Soon!</p>
          </Col>
        </Col>
      </Row>
      
      </div>
      

    </div>
  );
}
